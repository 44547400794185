<template>
  <div class="row">
    <div class="col-auto" v-for="hobby in user_info.hobbies" :key="hobby.id">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4"  >
            <div class="flex-1 content ms-3">
                <h6 class="title text-center mb-0">{{ hobby.name }}</h6>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['user_info','editable'],
}
</script>

<style>

</style>