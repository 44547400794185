<template>
    <div class="row">
        <div v-for="skill in user_info.skills" :key="skill.id" class="col-md-6">
            <h6 class="mt-1">
                <svg class="text-default" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
                <strong>{{ skill.name }}</strong>, I am
                <span v-if="skill.proficiency == 1" class="text-default">Biginner</span>
                <span v-else-if="skill.proficiency == 2" class="text-default">Intermediate</span>
                <span v-else class="text-default">Expert</span>  with
                <span v-if="skill.experience == 1" class="text-default">Less than 1 year of Experience </span>
                <span v-else-if="skill.experience == 2" class="text-default">1+ Year of Experience </span>
                <span v-else-if="skill.experience == 3" class="text-default">3+ Year of Experience </span>
                <span v-else class="text-default mt-1">5+ Years Experience </span>
            </h6>
        </div>
        <hr>
    </div>
</template>

<script>
export default {
    props: ['user_info','editable'],
}
</script>

<style>

</style>