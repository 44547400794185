<template>
    <Navbar :user="user"/>
    <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="row">
                <div class="col-md-2" id="sticky-sidebar">
                    <div class="sticky-top">
                        <Sidebar :user="user" />
                    </div>
                </div>
                <div class="col" id="main">
                    <div class="instructor-details-desc">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-md-10">
                                <Resume :email="user.email" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
        <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/trainer/Sidebar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import Resume from '@/components/trainer/Resume.vue'
export default {
 components: { Navbar,Loading, Sidebar, Resume },
   data(){
      return {
          drawer: true,
          currentUser: {},
          user: {},
          jobs: {},
          user_info: {
            profile: [0],preference_countries: [],preference_industries: [],candidate_jobs: [], education: [], experiences: [], skills: [], languages: [], specialists : [], memberships: [], trainings: []
            , certificates: [], hobbies: [], referees: []
            },
            salary: [],
            employmentTypes:[],
          editable: false,
          token: localStorage.getItem('user_data'),
          subscriber: {},
          isLoading: true,
      }
  },
 methods: {
      getUser(){
        axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
            this.user = response.data
        }).catch(errors => {
            if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
     
  },
  created(){
      document.title = `Dashboard - Training`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
  }

}
</script>

<style>

</style>