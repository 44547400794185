<template>
    <div class="row">
        <div class="col-md-6" v-for="referee in user_info.referees" :key="referee.id">
            <h5>
                <strong>{{ referee.name }}</strong>
            </h5>
            <h6>
            <span class="text-muted">{{ referee.job_title }} at {{ referee.working_institution }}</span> 
            </h6>
            <h6>
            <span class="text-muted">{{ referee.email }}, {{ referee.phone }}</span> 
            </h6>
            
        </div>
    </div>
</template>

<script>

export default {
    props: ['user_info','editable'],
}
</script>

<style>

</style>