<template>
    <div class="row">
        <div class="col-md-6" v-for="training in user_info.trainings" :key="training.id">
            <h5>
                <strong>{{ training.name }}</strong>
            </h5>
            <h6>
            <span class="text-muted">{{ training.institute }}</span> 
            </h6>
            <h6>
            <span class="text-muted">{{ training.hours }}hrs</span> 
            </h6>
            <h6>
            <span class="text-muted">{{  moment(new Date(training.start_day)).format('DD/MM/YYYY') }} to <span>{{  moment(new Date(training.end_day)).format('DD/MM/YYYY') }}</span></span> 
            </h6>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['user_info','editable'],
    created(){
    this.moment = moment
}

}
</script>

<style>

</style>