<template>
<div class="row">
        <div class="col-md-6" v-for="member in user_info.memberships" :key="member.id">
        <h5>
                <strong>{{ member.name }}</strong>
            </h5>
            <h6>
            <span class="text-muted">{{ member.role }}</span> 
            </h6>
            <h6>
            <span class="text-muted">{{  moment(new Date(member.start_day)).format('DD/MM/YYYY') }}</span> 
            </h6>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['user_info','editable'],
    created(){
    this.moment = moment
}
}
</script>

<style>

</style>