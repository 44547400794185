<template>
    <div>
        <div v-for="edu in user_info.education" :key="edu.id">
            <h5 class="mt-1">
                <strong>{{ edu.level.name }}, {{ edu.faculty }}</strong>
            </h5>
            <h6 class="mt-1">
                at <span>{{ edu.name }}</span>
            </h6>
            <h6 class="mt-1">
            <span class="text-muted">{{ edu.city }}, {{ edu.country }}</span> 
            </h6>
            <h6 class="mt-1">
            <span class="text-muted">{{  moment(new Date(edu.started_at)).format('DD/MM/YYYY') }} to <span v-if="edu.studying == 1">To Present</span> <span v-else>{{  moment(new Date(edu.ended_at)).format('DD/MM/YYYY') }}</span></span> 
            <span class="text-default" v-if="edu.marks"> Graduated marks: {{ edu.marks }}</span>
            </h6>
        </div>
    </div>
   
</template>

<script>
import moment from 'moment'
export default {
    props : ['user_info','editable'],
created(){
    this.moment = moment
}
}
</script>

<style>

</style>