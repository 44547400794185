<template>
<div>
       <h6 class="mt-1">
            <span class="fw-bold">Email:</span> {{ user_info.email }}
        </h6>
        <h6 class="mt-1">
            <span class="fw-bold">Phone:</span> {{ user_info.profile[0].mobile_phone }}
        </h6>
        <h6 class="mt-1">
           <span class="fw-bold">Website:</span> {{ user_info.profile[0].website }} 
        </h6>
    </div>
</template>

<script>

export default {
    props : ['user_info','editable'],
}
</script>

<style>

</style>