<template>
    <div >

       <h6 class="mt-1">
            <strong>Full Name: </strong> <span >{{ user_info.fname }} {{ user_info.sname }} {{ user_info.lname }}</span>
        </h6>
        <h6 class="mt-1">
            <strong>Age:</strong> <span>{{ age }} Years</span>
        </h6>
        <h6 class="mt-1">
           <strong>Gender</strong> <span>{{ user_info.profile[0].sex }} </span> 
        </h6>
        <h6 class="mt-1" v-if="user_info.profile[0].country">
           <strong>Nationality: </strong> <span>{{ user_info.profile[0].country.name }} </span> 
        </h6>
        <h6 class="mt-1" v-if="user_info.profile[0].city">
           <strong>City:</strong> <span>{{ user_info.profile[0].city.name }}</span> 
        </h6>
        <h6 class="mt-1">
           <strong>Visa Status: </strong> <span>{{ user_info.profile[0].visa }}</span>  
        </h6>
        <h6 class="mt-1">
           <strong>Martial Status: </strong> <span>{{ user_info.profile[0].martial_status }}</span> 
        </h6> 
        <h6 class="mt-1">
            <strong>Number Of Dependants: </strong> <span>{{ user_info.profile[0].dependants }}</span>
        </h6>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
export default {
    props: ['user_info','editable'],
     data(){
      return {
          profile: [],
          age: 0,
        }
    },
    methods:{
        getAge(){
            axios.get('https://api.vipawaworks.com/api/profile/getAge/' + this.user_info.id).then(response => {
                this.age = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
      created(){
      this.getAge()  
       this.moment = moment
    }
}
</script>

<style>

</style>