<template> 
    <div class="row">
        <div class="col-md-6" v-for="certificate in user_info.certificates" :key="certificate.id">
            <h5>
                <strong>{{ certificate.name }}</strong>
            </h5>            
            <h6>
            <span class="text-muted">validity From: {{  moment(new Date(certificate.validity_start)).format('DD/MM/YYYY') }} to <span>{{  moment(new Date(certificate.validity_end)).format('DD/MM/YYYY') }}</span></span> 
            </h6>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['user_info','editable'],
    created(){
    this.moment = moment
}

}
</script>

<style>

</style>