<template>
<div v-for="experience in user_info.experiences" :key="experience.id">
        <h5 class="mt-1">
            <strong>{{ experience.position }} at {{ experience.company_name }}</strong>
        </h5>
        <h6 class="mt-1">
           <span class="text-muted">{{ experience.city }}, {{ experience.country }}.</span><span class="text-muted"> from {{  moment(new Date(experience.started_at)).format('DD/MM/YYYY') }} to <span v-if="experience.working == 1">To Present</span> <span v-else>{{  moment(new Date(experience.ended_at)).format('DD/MM/YYYY') }}</span></span> 
        </h6>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props : ['user_info','editable'],
    created(){
    this.moment = moment
}

}
</script>

<style>
.margin-bottom-0{
    margin-bottom: 0rem !important;
}
</style>