<template>
  <div>
        <div v-for="job in user_info.candidate_jobs" :key="job.id">
            <h6 class="mt-1">
                <span class="fw-bold">Job Title:</span> {{ job.name }}
            </h6>
            <h6 class="mt-1">
                <span class="fw-bold">Job Category:</span> {{ job.category.name }}
            </h6>
        </div>
        <div v-for="country in user_info.preference_countries" :key="country.id">
            <h6 class="mt-1">
                <span class="fw-bold">Preference Country:</span> {{ country.name }}
            </h6>
        </div>
        <div v-for="industry in user_info.preference_industries" :key="industry.id">
            <h6 class="mt-1">
                <span class="fw-bold">Preference Industry:</span> {{ industry.name }}
            </h6>
        </div>
        <div v-for="type in employmentTypes" :key="type.id">
            <h6 class="mt-1">
                <span class="fw-bold">Employment Type:</span>
                <span v-if="type.type_id == 1">Consultant</span>
                <span v-if="type.type_id == 2">Contractor</span>
                <span v-if="type.type_id == 3">Employee</span>
                <span v-if="type.type_id == 4">Temporary Employee</span>
                <span v-if="type.type_id == 5">Volunteer</span>
            </h6>
        </div>
        <div>
            <h6 class="mt-1" v-for="sal in salary" :key="sal.id">
                <span class="fw-bold">Target monthly salary:</span>
                <span v-if="sal.current_currency == 1">USD:</span>
                <span v-if="sal.current_currency == 2">TSH:</span>
                {{ Number(sal.current).toLocaleString()  }}
            </h6>
        </div>
       
    </div>
</template>

<script>
export default {
    props:['user_info','salary','employmentTypes','editable'],

}
</script>

<style>

</style>