<template>
    <div class="row">
        <div  class="col-md-6" v-for="language in user_info.languages" :key="language.id">
            <h6>
                <svg class="text-default" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
                <strong>{{ language.name }}</strong>, I am
                    <span v-if="language.proficiency == 1" class="text-default">Biginner</span>
                    <span v-else-if="language.proficiency == 2" class="text-default">Intermediate</span>
                    <span v-else-if="language.proficiency == 3" class="text-default">Expert</span>
                    <span v-else>5 Years +</span>
            </h6>
        </div>
        <hr>
    </div>
</template>

<script>

export default {
    props: ['user_info','editable'],
}
</script>

<style>

</style>