<template>
    <div v-if="user_info && user_info.profile[0]" class="col-lg-12 col-12">
        <div class="card border-0 rounded shadow-lg">
            <div class="card-body">
                <div class="row justify-content-center d-sm-flex">
                    <div class="col-md-2">
                        <div v-if="user_info.avatar">
                            <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/img/user1.jpg" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                        </div>
                    </div>
                    <br>
                </div>
                <hr>
                <div ref="content">
                    <br>
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="row justify-content-center d-sm-flex">
                                <div v-if="user_info.avatar" class="col-md-4">
                                    <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar  avatar-large shadow me-md-4" alt="">
                                </div>
                                <div v-else class="col-md-4">
                                    <img src="@/assets/img/user1.jpg" class="avatar avatar-large shadow me-md-4" alt="">
                                </div>
                                <div class="col-md-8 text-start">
                                    <h3 class="margin-bottom-0 mt-1">{{ user_info.fname }} {{ user_info.sname }}  {{ user_info.lname }}</h3>
                                    <div v-for="experience in user_info.experiences.slice(0, 1)" :key="experience.id">
                                        <h6 class="margin-bottom-0 mt-1">{{ experience.position }}</h6>
                                        <h5 class="text-muted margin-bottom-0 mt-1">{{ experience.company_name }}</h5>
                                    </div>
                                    <p class="text-muted margin-bottom-0"><span v-if="user_info.profile[0].city">{{ user_info.profile[0].city.name }},</span> {{ user_info.profile[0].country.name}}</p>
                                    <div v-for="edu in user_info.education" :key="edu.id">
                                        <p class="text-muted margin-bottom-0 mt-1">{{ edu.faculty }}, {{ edu.name }}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="shadow-sm p-3 bg-body rounded">
                                <div class="row  text-start">
                                    <div class="col-md-4"><br>
                                        <h5>Basic Information</h5>
                                    </div>
                                    <div class="col-md-8">
                                        <PersonalInfo :user_info="user_info" :editable="0"/>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <div class="shadow-sm p-3 bg-body rounded">
                                <div class="row  text-start" v-if="user_info.profile[0].private == 0">
                                    <div class="col-md-4"><br>
                                        <h5>Contact Information</h5>
                                    </div>
                                    <div class="col-md-8">
                                        <BasicInfo :user_info="user_info" :editable="0"/>
                                    </div>
                                </div>
                                <div class="row text-start" v-else>
                                    <div class="col-md-4"><br>
                                        <h5>Contact Information</h5>
                                    </div>
                                    <div class="col-md-8 alert alert-danger">
                                        <h5 class="text-center">This Account is Private</h5>
                                        <p class="text-center">This candidate Has limited people who can contact him directly, via mobile phone or email.</p>
                                        <p class="text-center text-dark">Tip:He/She may be a very compitent candidate</p>
                                    </div>
                                </div>
                            </div>
                            <div class="shadow-sm p-3 bg-body rounded">
                                <div class="row  text-start">
                                    <div class="col-md-4"><br>
                                        <h5>Preferred Job</h5>
                                    </div>
                                    <div class="col-md-8">
                                        <TargetJob :user_info="user_info" :salary="salary" :employmentTypes="employmentTypes" :editable="0" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.education.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                    <div class="col-md-4">
                                        <h5>Education Background</h5>
                                    </div>
                                    <div class="col-md-8">
                                        <EducationInfo :user_info="user_info" :editable="0"/>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div v-if="user_info.experiences.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4"><br>
                                            <h5>Work Experience</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <ExperienceInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.specialists.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Specialists</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <SpecialistInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.skills.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Skills</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <SkillsInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.languages.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Languages</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <LanguagesInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.trainings.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Trainings</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <TrainingsInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.certificates.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Certifications</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <CertificatesInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.memberships.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Membership</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <MembershipInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.hobbies.length">
                                <div class="shadow-sm p-3 bg-body rounded">
                                    <div class="row text-start">
                                        <div class="col-md-4">
                                            <h5>Hobbies</h5>
                                        </div>
                                        <div class="col-md-8">
                                            <HobbiesInfo :user_info="user_info" :editable="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user_info.referees.length">
                                <div class="row text-start">
                                    <div class="col-md-4">
                                        <h5>Referees</h5>
                                    </div>
                                    <div class="col-md-8">
                                        <RefereesInfo :user_info="user_info" :editable="0"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
        </div>
    </div>
    <div v-else class="col-lg-12 col-12">
        <div class="alert alert-danger justify-content-center">
            <!-- <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block"> -->
            <h4 class="text-center">Empty Resume</h4>
            <p class="text-center">You have not filled up resume yet</p>
            <div class="row justify-content-evenly">
                <div class="col-md-2 mt-3 ">
                    <a href="https://new.vipawaworks.com/myresume" class="btn default-btn mt-2 border-0" target="_blank">
                        <span>Fill Now in Vipawaworks</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonalInfo from '@/components/trainer/resume/PersonalInfo.vue'
import BasicInfo from '@/components/trainer/resume/BasicInfo.vue'
import EducationInfo from '@/components/trainer/resume/EducationInfo.vue'
import ExperienceInfo from '@/components/trainer/resume/ExperienceInfo.vue'
import SkillsInfo from '@/components/trainer/resume/SkillsInfo.vue'
import LanguagesInfo from '@/components/trainer/resume/LanguagesInfo.vue'
import SpecialistInfo from '@/components/trainer/resume/SpecialistInfo.vue'
import MembershipInfo from '@/components/trainer/resume/MembershipInfo.vue'
import TrainingsInfo from '@/components/trainer/resume/TrainingInfo.vue'
import CertificatesInfo from '@/components/trainer/resume/CertificatesInfo.vue'
import RefereesInfo from '@/components/trainer/resume/RefereesInfo.vue'
import HobbiesInfo from '@/components/trainer/resume/HobbiesInfo.vue'
import TargetJob from '@/components/trainer/resume/TargetJob.vue'
import axios from 'axios'
export default {
components: { 
      PersonalInfo, BasicInfo,EducationInfo,ExperienceInfo, SkillsInfo,
      LanguagesInfo,SpecialistInfo,MembershipInfo,TrainingsInfo,CertificatesInfo,RefereesInfo,HobbiesInfo,TargetJob
    },
    props:['email'],
    data(){
      return {
        user_info: {
        profile: [0],preference_countries: [],preference_industries: [],candidate_jobs: [], education: [], experiences: [], skills: [], languages: [], specialists : [], memberships: [], trainings: []
        , certificates: [], hobbies: [], referees: []
        },
        salary: [],
        employmentTypes:[],
      }
    },
    methods:{
        getProfile(){
            axios.get('https://api.vipawaworks.com/api/training/profile/' + this.email).then(response => {
                this.user_info = response.data
                document.title =  this.user_info.fname + ' Resume - Vipawaworks'
            axios.get('https://api.vipawaworks.com/api/training/targetSalary/' + this.email).then(response => {
                this.salary = response.data
            })
            axios.get('https://api.vipawaworks.com/api/training/employmentTypes/' + this.email).then(response => {
                this.employmentTypes = response.data
            })
        }).catch(errors => {
            this.errors = errors.response.data.errors
        })
        },
    },
    created(){
        this.getProfile()
    }
}
</script>

<style>
.avatar-large{
    height: 140px;
    width: 140px;
}
</style>