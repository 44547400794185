<template>
    <div class="row">
        <div class="col-auto" v-for="specialist in user_info.specialists" :key="specialist.id">
            <div class="d-flex key-feature align-items-center p-3 rounded shadow"  >
                <div class="flex-1 content ms-3">
                    <h6 class="title text-dark mb-0">{{ specialist.name }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['user_info','editable'],

}
</script>

<style>

</style>